<template>
	<div>
		<div class="button-chart-container">
			<button
				class="ml-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
				v-if="showChart"
				@click="handleChartVisibility(false)"
			>
				{{ $t('berthplanner.hidedraught') }}
			</button>
			<button
				class="ml-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
				v-else
				@click="handleChartVisibility(true)"
				:disabled="disableButton"
				:class="{ 'grey-bg': disableButton }"
			>
				{{ $t('berthplanner.showdraught') }}
			</button>
		</div>
		<div v-show="showChart" class="chart-container" ref="chartContainer"></div>
	</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
	name: 'ChartComponent',

	props: {
		bollards: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			showChart: true,
			disableButton: false
		};
	},

	mounted() {
		this.createChart();
	},

	watch: {
		bollards() {
			this.createChart();
			if (this.bollards.length == 0 || this.bollards[0].id == null) {
				this.disableButton = true;
				this.showChart = false;
			} else {
				this.disableButton = false;
			}
		}
	},

	methods: {
		handleChartVisibility(value, firstChange = false) {
			this.showChart = value;
			if (value) {
				this.createChart();
				this.$puiEvents.$emit('berthplanner-toolbar_zoomChanged', -0.2);
			} else {
				this.$puiEvents.$emit('berthplanner-toolbar_zoomChanged', 0.2);
			}
		},
		generateArrayDraught() {
			return this.bollards.map((bollard) => (bollard.draught != null ? bollard.draught : 0));
		},

		generateArrayBolcode() {
			return this.bollards.map((bollard) => bollard.bolcode);
		},

		createChart() {
			const chart = echarts.init(this.$refs.chartContainer);

			const yValues = this.generateArrayDraught();
			const minValue = Math.min(...yValues);
			const maxValue = Math.max(...yValues);

			const xValues = this.generateArrayBolcode();

			const option = {
				grid: {
					left: '190px',
					right: '1%',
					top: '20%',
					bottom: '20%'
				},
				xAxis: {
					type: 'category',
					data: xValues,
					position: 'top',
					boundaryGap: false
				},
				yAxis: {
					type: 'value',
					min: minValue,
					max: maxValue
				},
				series: [
					{
						name: '',
						type: 'line',
						data: yValues,
						lineStyle: {
							color: 'rgba(0, 0, 128, 0.5)'
						},
						areaStyle: {
							color: 'rgba(0, 0, 128, 0.5)'
						},
						silent: true
					}
				],
				dataZoom: [],
				tooltip: {
					trigger: 'none'
				},
				toolbox: {
					show: false
				},
				pointer: {
					type: 'none'
				}
			};

			chart.setOption(option);
		}
	}
};
</script>

<style scoped>
.chart-container {
	width: 100%;
	height: 150px;
}
.button-chart-container {
	margin-top: 10px;
	margin-right: 1%;
	display: flex;
	justify-content: end;
}
.grey-bg {
	background-color: grey !important;
	color: white;
	cursor: not-allowed;
}
</style>
